$(document).ready(function () {
    $("#add-blog-btn").on("click", function (e) {
        e.preventDefault();
        let form = document.getElementById("blog");
        let formData = new FormData(form);

        $.ajax({
            url: "/blog",
            method: "POST",
            data: formData,
            processData: false,
            contentType: false,
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (response) {
                if (response.success) {
                    toastr.success(response.message);
                    window.location.href = response.redirect_url;
                    form.reset();
                } else {
                    toastr.error("Failed to create blog post.");
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                toastr.error("An error occurred. Please try again.");
            },
        });
    });

    $("#blog_image").change(function (event) {
        const file = event.target.files[0];
        const imagePreview = $("#imagePreview");
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                imagePreview.attr("src", e.target.result);
                imagePreview.css("display", "block");
            };
            reader.readAsDataURL(file);
        } else {
            imagePreview.attr("src", "#");
            imagePreview.css("display", "none");
        }
    });

    $("#explore-id").on("click", function () {
        var blogId = $(this).data("explore-id");

        $.ajax({
            url: "/get-blog-details", // Update this to match your route
            method: "GET",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                blog_id: blogId,
            },
            success: function (response) {
                if (response.status === "success") {
                    window.location.href = "/blog/" + blogId;
                }
            },
            error: function (xhr, status, error) {
                console.error("Error:", error);
                alert("Failed to fetch blog details. Please try again.");
            },
        });
    });

    // Blog edit
    $(document).on("click", "#edit-blog", function (e) {
        e.preventDefault();
        var blogId = $(this).data("id");
        window.location.href = "/blog/" + blogId + "/edit";
    });

    $(document).on("click", "#update-blog", function (e) {
        e.preventDefault();
        var blogUpdateId = $(this).data("update-id");
        let form = document.getElementById("blog");
        let formData = new FormData(form);
        formData.append("blogUpdateId", blogUpdateId);
        $.ajax({
            url: "/blog/" + blogUpdateId,
            method: "POST",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },

            data: formData,
            processData: false,
            contentType: false,
            beforeSend: function () {
                formData.append("_method", "PUT");
            },
        })
            .done(function (response) {
                toastr.success(response.message);
                window.location.href = response.redirectUrl;
            })
            .fail(function (xhr, status, error) {
                console.error("Error:", error);
                alert("Failed to fetch blog details. Please try again.");
            });
    });

    $(document).on("click", "#delete-blog", function (e) {
        e.preventDefault();
        var blogDeleteId = $(this).data("delete-id");

        $.ajax({
            url: "/blog/" + blogDeleteId,
            method: "DELETE",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },

            data: {
                blogDeleteId: blogDeleteId,
            },
        })
            .done(function (response) {
                toastr.success(response.message);
                window.location.href = response.redirectUrl;
            })
            .fail(function (xhr, status, error) {
                console.error("Error:", error);
                alert("Failed to fetch blog details. Please try again.");
            });
    });

    const quill = new Quill('#content-editor', {
        theme: 'snow',
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline'],
                ['link', 'image', 'video'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['clean']
            ]
        }
    });

    quill.on("text-change", function () {
        const blogContent = quill.root.innerHTML;
        $("#content").val(blogContent);
    });
});
